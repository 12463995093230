// src/views/Login.vue

<template>
  <div class="pa-6">

           <h2 class="primary--text">Login</h2>  
           
           <!--{{this.$route.params.id}} 


            <v-btn icon small class="ml-7 mt-2" @click="$emit('check1')">
              emitting drawer check1
            </v-btn> -->

          <!-- ICONS    https://materialdesignicons.com/icon/view-dashboard -->

          <v-layout row wrap >

            <v-flex xs10 md7 lg7 class="ma-6">
              <v-text-field 
                label="Benutzname"
                v-model="username"
                :rules="rules"
                hide-details="auto"
                prepend-inner-icon="mdi-account"
              >
              </v-text-field>
            </v-flex>


          <v-flex xs10 md7 lg7 class="ma-6">
              <!-- <v-text-field
                label="Passwort"
                v-model="password"
                :rules="rules"
                hide-details="auto"
                prepend-inner-icon="mdi-lock"
              ></v-text-field> -->

              <v-text-field
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="password"
                :rules="rules"
                :type="show3 ? 'text' : 'password'"
                name="input-10-2"
                label="Passwort"
                hint="At least 8 characters"
                value="wqfasds"
                class="input-group--focused"
                prepend-inner-icon="mdi-lock"
                @click:append="show3 = !show3"
              
              ></v-text-field>

          </v-flex>

        


          <v-flex xs10 md7 lg7 class="ma-6">
              <v-btn class="ma-8 right"
                depressed
                color="secondary"
                @click="login"
              >
                Login
              </v-btn>
          </v-flex>

          </v-layout>
            
              <!-- <p v-if="msg"> <span class="error--text"> {{msg}} </span></p> -->
  </div>
</template>
<script>


import AuthService from '@/services/AuthService.js';

import { EventBus } from '../main';

export default {
  data() {
    return {
      rules: [
        value => !!value || 'Pflichtfeld.',
        value => (value && value.length >= 7) || 'Mind 7 Zeichen',
      ],
      show3: false,
      username: '',
      password: '',
      msg: '',
      angemeldeter_user:'',
      id_user:''
    };
  },

  created() {

    EventBus.$on('emit_logout', eventbusparameter => {
          this.eventbusmsg = eventbusparameter;

          this.username = '',
          this.password = '';
        })
          
    },

   
  props: {
    id: { 
      required: false,
    },
  },
  methods: {


    anmeldung_zuruecksetzen() {
      alert('test');
    },


    async login() {
      try {
        const credentials = {
          username: this.username,
          password: this.password
        };
        const response = await AuthService.login(credentials);
        this.msg = response.msg;
        this.angemeldeter_user = response.user;
        this.id_user = response.id_user;

        //***********  Michael localstorage im Browser  --deaktiviert im Januar 23 wegen Sichehereit*/
       // localStorage.setItem("angemeldeter_user", response.user);
       // localStorage.setItem("user_id", this.msg);  //*******************  bei Gelegenheit rausnehmen --  wird besser im VUE-Store (store.js) gespeichert  */

        const token = response.token;
        // const user = response.user;
        // const user_id = response.user_id;

        //***********  Michael    Store in VUEX  (store.js)  ****/
        //this.$store.dispatch('login', { token, user, user_id });
      //  console.log('121 login: userID: ' + this.msg)
        //this.$store.commit('SET_USERID', this.user_id );
        this.$store.commit('SET_USERID', this.msg );
        this.$store.dispatch('login', { token});


        //console.log('Login 127 login: ' + this.$store.state.user_id)

        this.$router.push('/Schiffsliste');
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  }
};
</script>